import React, { Fragment } from "react";
import { getStyles } from "../../../assets/js/utils/functions";

interface TextProps {
  series: string[][];
  className?: string;
}

const AnimatedHeaderText: React.FC<TextProps> = ({ series, className }) => {
  const flattenedSeries = (() => {
    let flat: string[] = [];
    series.forEach((series) => {
      flat = flat.concat(series);
    });

    return flat;
  })();

  const styles = (text: string) => {
    const styles = getStyles({ "--delay": `${flattenedSeries.indexOf(text) * 0.125}s` });
    return styles;
  };

  return (
    <div className="hide-for-observer">
      <h2 className={`text-black heading-three font-bold ${className ?? ""}`}>
        {series.map((s, index) => {
          return (
            <div className={`words-line whitespace-nowrap ${index % 2 === 1 ? "text-page" : ""}`} key={index}>
              {s.map((word, i) => {
                return (
                  <Fragment key={i}>
                    <span className="inline-block anim-word-up" style={styles(word)}>
                      {word}
                    </span>{" "}
                  </Fragment>
                );
              })}
            </div>
          );
        })}
      </h2>
    </div>
  );
};

export default AnimatedHeaderText;
